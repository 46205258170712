import survey from './survey/index.js';

const handleInvocation = (parameters) => {
  const functionName = parameters[0];

  if (typeof survey[functionName] == "function") {
    survey[functionName].apply(null, Array.from(parameters).slice(1));
    return;
  }

  window.console.warn(`CustomerX Survey Widget: Undefined function ${functionName}`)
  return;
};

let acessorKey = window.CustomerXSurveyObject;
if (acessorKey) {
  const acessorParts = acessorKey.split('.');
  acessorKey = acessorParts[acessorParts.length -1];
  let acessor = acessorParts.slice(0, -1).reduce((acc, acessorPart) => {
    if (!acc[acessorPart]) {
      acc[acessorPart] = {};
    }
    return acc[acessorPart];
  }, window);
  
  const invocations = acessor[acessorKey].q;
  
  for (let invocation of invocations || []) {
    handleInvocation(invocation);
  }
  
  acessor[acessorKey] = function () {
    handleInvocation(arguments);
  }
}
